import React, { Component } from 'react'
import './billing.css'
import Invoices from './Invoice';
import CreditCardList from './CreditCardList';
import Modal from "../Pages/ChatSetup/components/Modal";
import Subcription_Cancellation from './Subcription_Cancellation';
import billing from "../../api/billing";
import partnerBilling from "../../api/partnerBilling";
import MonthlyReengage from "./MonthlyReengage";
import config from "../../config";
import { Spinner } from 'react-bootstrap';
import { Loader } from 'semantic-ui-react';
export default class Billing extends Component {
    constructor( props ){
        super(props);
        this.state = {
            isUserSubscribes : false,
            user : props.user,
            creditcardsDetail:[] ,
            invoicesDetail:[] ,
            userBillingDetail : {},
            cardloader      : false,
            invoiceloader : true ,
            openRefileamountModel : false,
            openRefilelimitModel : false,
            openmodleTitle : "",
            openRefilelimitinput: "",
            openRefileAmountInput: "",
            openRefileError : "",
            refilLimitValue : null,
            refilAmountValue : null,
            refileSuccessNFailure : "",
            userlinktoken:"",
            next_invoice:0
           
        }
        this.myRef = React.createRef();
        
    }

    componentDidMount(){
        if( this.state.user.userBillingDetail.is_billing_record_available === 1 ){
            this.setState({
                userBillingDetail : this.state.user.userBillingDetail.user_billing_overview[0],
                invoicesDetail : this.state.user.userBillingDetail.invoices,
                creditcardsDetail : this.props.user.userBillingDetail.cards,
                next_invoice:this.props.user.userBillingDetail.next_invoice,
                invoiceloader : false,
            }, () => this.setloader());
            billing.GetUserLinktoken({user_id:this.state.user.user_id })
            .then((userResp) => {
                this.setState({
                    ...this.state,
                    userlinktoken:userResp.usertoken
                });
            });
        }
          
        
    }
    
    setloader = () => {
        this.setState({cardloader:true})
    }
    subscriptionError = ( state ) => {
        let error = '';
         if( state.userBillingDetail.subscription_status=='DEACTIVE' && state.creditcardsDetail.length == 0 ){
            error = "Please add a payment method below. Failure to do so can cause your re-engage playbooks to be paused.";
        }else if( state.userBillingDetail.subscription_status=='DEACTIVE' && state.creditcardsDetail.length > 0 ){
            error = "Please activate your subscription. Failure to do so can cause your re-engage playbooks to be paused.";
        } 
        if( error != '' ){
            return <div class="alert alert-danger">{error}</div>
        }
    }
    changePrimaryCard = ( cardNo,expireson) => {
        let newuserBillingDetail = {...this.state.userBillingDetail,card_number:cardNo,card_expires_on:expireson};
        this.setState({
            ...this.state,
            userBillingDetail: newuserBillingDetail
        });
    }

    updateUserBillling=()=>{

        billing.GetUserBillingInfo( {user_id:this.state.userBillingDetail.user_id })
        .then((userResp) => {
            this.setState({
                ...this.state,
                userBillingDetail:userResp.data
            });
        });

        billing.GetUserCards( {user_id:this.state.userBillingDetail.zoho_customer_id})
        .then((userResp) => {
            if(userResp.msg=="done"){
                this.setState({creditcardsDetail:userResp.data});
                
            }
        });
        this.forceUpdate();
        this.subscriptionError( this.state );
    }

    updatecard = ( cards = []) => {
        this.subscriptionError( this.state );
        if( cards ){
            this.setState({
                ...this.state,
                creditcardsDetail:cards
            });
        }
    }
    closeModal = () => {
        this.setState({
            ...this.state,
            openRefileamountModel:false,
            openRefilelimitModel:false,
            refilAmountValue : null,
            refilLimitValue : null,
            openRefileError : '',
        })
        document.getElementById('refilAmountValueform').value = '';
        document.getElementById('refilLimitValueform').value = '';
    }
    openRefileModel = ( modelType ) => {
        if(modelType === 'amount'){
            this.setState({
                ...this.state,
                openRefileamountModel:true,
                openRefilelimitModel:false
            })
            
        }else if(modelType === 'limit'){
            this.setState({
                ...this.state,
                openRefileamountModel:false,
                openRefilelimitModel:true
            })
        }
    }

    onResponseRefileModification(){
        if(this.state.refileSuccessNFailure == 'success'){
            return <div class="alert alert-success" >{"Data updated succesfully. "}</div>;
        }else if(this.state.refileSuccessNFailure == 'failure'){
            return <div class="alert alert-danger">{"Please try after some time. "}</div>;
        }else{
            return "";
        }
    }
    onHandleChangeEvent( event ){
        let value = event.target.value;
        let field = event.target.name;
        let errormsg = '';
        let pattern = /^[0-9\.]+$/.test(value);
        value = parseFloat(value);
        if(!isNaN( value ) && value > 0 && pattern ) {
            errormsg = "";
        }else{
            errormsg = "Value should be interger and value is greater than 0.";
        }
        if(errormsg === ''){
            this.setState({...this.state,[field] :value,openRefileError :errormsg});
        }else{
            this.setState({...this.state,openRefileError :errormsg});
        }
    }
    onSubmitRefile( event,type ){
        event.preventDefault();
        let formdata = null;
        if( this.state.openRefileError === ''){
            if(type === 'limit'){
                if(this.state.refilLimitValue ){
                    formdata = {
                        refil_drop_amount:this.state.refilLimitValue,
                        user_id:this.state.userBillingDetail.user_id,
                        field : 'refil_drop_amount'
                    };
                    partnerBilling.modifyRefileRule(formdata).then(( userRes) => {
                        if(userRes.status){
                            console.log(this.state);
                            this.setState({
                                ...this.state,
                                userBillingDetail : {
                                    ...this.state.userBillingDetail,
                                    refil_drop_amount : userRes.data
                                },
                                refileSuccessNFailure :"success",
                                openRefileamountModel:false,openRefilelimitModel:false
                            },
                                () => setTimeout(() => {
                                    this.setState({...this.state,refileSuccessNFailure :""}
                                );
                            }, 10000));
                            window.scrollTo(0,0);
                        }
                        
                    })
                }else{
                    return false;
                }
            }else{
                formdata = {refill_amount:this.state.refilAmountValue};
                /* this.setState({...this.state,refileSuccessNFailure :"failure",openRefileamountModel:false,openRefilelimitModel:false},() => setTimeout(() => {
                    this.setState({...this.state,refileSuccessNFailure :""});
                }, 10000)); */
                if(this.state.refilAmountValue ){
                    formdata = {
                        refill_amount:this.state.refilAmountValue,
                        user_id:this.state.userBillingDetail.user_id,
                        field : 'refill_amount'
                    };
                    partnerBilling.modifyRefileRule(formdata).then(( userRes) => {
                        if(userRes.status){
                            this.setState({
                                ...this.state,
                                userBillingDetail : {
                                    ...this.state.userBillingDetail,
                                    refill_amount : userRes.data
                                },
                                refileSuccessNFailure :"success",
                                openRefileamountModel:false,openRefilelimitModel:false
                            },
                                () => setTimeout(() => {
                                    this.setState({...this.state,refileSuccessNFailure :""}
                                );
                            }, 10000));
                            window.scrollTo(0,0);
                        }
                        
                    })
                }else{
                    return false;
                }
            }
        }
        document.getElementById('refilAmountValueform').value = '';
        document.getElementById('refilLimitValueform').value = '';
        return false;
    }

    formRefilelimitModel = ( type ) => {
        if(type == 'limit'){
            return (
                <form onSubmit={(event) => this.onSubmitRefile( event,type )} >
                    <label>Current Value : ${this.state.userBillingDetail.refil_drop_amount}</label>
                    <input type="text" class="form-control" name="refilLimitValue" onChange={(event) => this.onHandleChangeEvent(event)} id="refilLimitValueform"/>
                    <span className="text-danger">{this.state.openRefileError}</span>
                    <div className="mt-3 d-flex justify-content-end">
                        <button onClick={() => this.closeModal()} className="btn btn-outline-secondary mx-3">Cancel</button>
                        <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                </form>
            )
        }else{
            return (
                <form onSubmit={(event) => this.onSubmitRefile( event,type )} >
                    <label>Current Value : ${this.state.userBillingDetail.refill_amount}</label>
                    <input type="text" class="form-control" name="refilAmountValue" onChange={(event) => this.onHandleChangeEvent(event)}  id="refilAmountValueform"/>
                    <span className="text-danger">{this.state.openRefileError}</span>
                    <div className="mt-3 d-flex justify-content-end">
                        <button onClick={() => this.closeModal()} className="btn btn-outline-secondary mx-3">Cancel</button>
                        <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                </form>
            )
        }
    }

    copyuserlink=(e)=>{
        //copy link
        var elem = document.querySelector('.copy--link');
        let link=config.serverBaseURL+"/subscribe_billing/"+ this.state.userlinktoken;
        var input = document.createElement('input');
        input.setAttribute('value', link);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        elem.innerText="copied";
        
        setTimeout(() => {
            elem.innerText= "Copy Link"
        }, 1000);
    }
    getsubscription_link=()=>{
        if(this.state.userBillingDetail.subscription_status=="DEACTIVE" && this.state.userBillingDetail.subscription_id==""){
        return(
            <div className="row px-4 mb-4">
                <div className="col-sm-12">
                    <div className="subslink">
                        <a target="_blank" className='text-info' href={config.serverBaseURL+"/subscribe_billing/"+ this.state.userlinktoken}>Register and subscribe for billing</a>
                        <button className="btn btn-outline-dark badge ml-3 copy--link" onClick={(e)=>this.copyuserlink(e)}>Copy Link</button>
                    </div>
                </div>
            </div>
        );
        }             
    }

    render() {
        
        if( this.state.user.userBillingDetail.is_billing_record_available === 0){
            return (
                <>
           
                    <div>No Billing data is not available</div>
                   
                  
                </>
            )
        }else{
            return (
                <>
                    <div className="container-fluid p-5 billing-css">
                        <div ref={this.myRef}></div>
                        {this.onResponseRefileModification()}
                        { this.subscriptionError( this.state ) }
                    
                      
                           { (this.state.cardloader && this.state.creditcardsDetail )  ?
                            <CreditCardList 
                                cardsList={this.state.creditcardsDetail}
                                default_card={this.state.userBillingDetail.card_number}
                                user={this.state.user}
                                billing_update={this.updateUserBillling}
                                changePrimaryCard={this.changePrimaryCard}
                                updateCards = {this.updatecard}
                                domref={this.myRef}
                            /> :
                            (
                            <div className="ui loading form"></div>
                            )
                           }
                        <hr className="mt-5" />
                        <div>
                            <div className="nav-tabs nav billing-tabs mb-3 px-4">
                                <a className="nav-link active" data-toggle="tab" href="#all">All</a>
                                <a className="nav-link" data-toggle="tab" href="#invoiceHistory">Invoice History</a>
                                <a className="nav-link" data-toggle="tab" href="#monthlyUsage">Monthly Usage</a>
                            </div>
                            <div className="tab-content mb-5">
                                <div className="tab-pane fade show active p-2" id="all">
                                    {/* {this.getsubscription_link()} */}
                                   
                                  
                                    <div className="row w-75 px-4">
                                        <div className="col-md-3">
                                            <div>
                                                <span>Base Monthly Fee</span>
                                                <h6>${this.state.userBillingDetail.voice_monthly_fee}</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <span>Cost Per AI Minute</span>
                                                <h6>{this.state.userBillingDetail.cost_per_minute ? "$"+this.state.userBillingDetail.cost_per_minute : 'NA'}</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <span>Minutes Included In Plan</span>
                                                <h6>{this.state.userBillingDetail.mins_in_plan}</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <span>Includes Chat</span>
                                                <h6>{this.state.userBillingDetail.chat_added=='y' ? 'Yes':'No'}</h6>
                                               {this.state.userBillingDetail.chat_added=='y' && <> <span>Chat Price</span><h6>${this.state.userBillingDetail.chat_amount}</h6></>}
                                    </div>
                                        </div>
                                       
                                        
                                    </div>
                                    <hr/>
                                   
                                    <div className="row w-75 px-4">
                                    {this.state.user.is_priority=='Yes' &&<div className="col-md-3">
                                            <div>
                                                <span>Priority Support</span>
                                             
                                                <h6>${this.state.userBillingDetail.priority_support_charge}</h6>
                                            </div>
                                        </div>}
                                        <div className="col-md-3">
                                            <div>
                                                <span>Invoice Date</span>
                                                <h6>{ this.state.userBillingDetail.voice_invoice_date}</h6>
                                               <p>(Usage from {this.state.userBillingDetail.invoice_start_date} to {this.state.userBillingDetail.invoice_end_date})</p>
                                               
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div>
                                                <span>Last Invoice</span>
                                                <h6>${this.state.userBillingDetail.last_invoice_amount}</h6>
                                               
                                        </div>
                                    </div>
                                        <div className="col-md-3">
                                            <div>
                                                <span>Next Invoice </span>
                                                <span>(This amount may continue to grow)</span>
                                                <h6>${this.state.next_invoice}</h6>
                                               
                                                </div>
                                            </div>
                                        </div>
                                    {/* <hr />
                                    <div className="row w-75 px-4">
                                    <div className='col-md-12'>
                                            <h3 style={{fontWeight:'600',marginBottom:'20px'}}>Engage</h3>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <span>Base Monthly Fee</span>
                                                <h6>${this.state.userBillingDetail.base_monthly_fee}</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <span>Next Billing Date</span>
                                                <h6>{this.state.userBillingDetail.next_billing_date ? this.state.userBillingDetail.next_billing_date : 'NA'}</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <span>Last Billing Date</span>
                                                <h6>{this.state.userBillingDetail.last_billing_date}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <div className="row w-75 px-4">
                                        <div className="col-md-3 mb-3">Engage usage billing</div>
                                    </div>
                                    <div className="row w-75 px-4">
                                        <div className="col-md-3">
                                            <div>
                                                <span>Cost per email</span>
                                                <h6>${parseFloat(this.state.userBillingDetail.cost_per_email).toFixed(2)}</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <span>Cost per SMS</span>
                                                <h6>${parseFloat(this.state.userBillingDetail.cost_per_sms).toFixed(2)}</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <span>Cost per postcard</span>
                                                <h6>${parseFloat(this.state.userBillingDetail.cost_per_postcard).toFixed(2)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row w-75  my-4 px-4">
                                        <div className="col-md-3">
                                            <div>
                                                <span>Current usage balance</span>
                                                <div className="d-flex align-items-end">
                                                    <div>${Math.abs(parseFloat(this.state.userBillingDetail.wallet_balane)).toFixed(2)}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div>
                                                <span>Refill rules</span>
                                                <div className="d-flex align-items-end">
                                                    <a className=" font-weight-bold mx-1" onClick = {() => this.openRefileModel('amount') } style={{cursor: "pointer"}}>
                                                        ${this.state.userBillingDetail.refill_amount}
                                                    </a> 
                                                    when balance drops below 
                                                    <a className="mx-1 font-weight-bold" onClick = {() => this.openRefileModel('limit') } style={{cursor: "pointer"}}>
                                                        ${this.state.userBillingDetail.refil_drop_amount}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='my-4' /> */}
                                   
                                    <hr />
                                </div>
                                <div className="tab-pane fade p-2" id="invoiceHistory">
                                    { !this.state.invoiceloader &&
                                        <Invoices invoiceHistory = {this.state.invoicesDetail} userId={this.state.user.user_id} />
                                    }
                                    {!this.state.invoicesDetail &&
                                        <React.Fragment>
                                            <h1 className="text-center text-dark py-4">
                                                <i className="fa fa-exclamation-triangle fa-2x mb-2 d-block"></i>
                                                <span>No Invoice Found</span>
                                            </h1>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="tab-pane fade p-2" id="monthlyUsage">
                                    <MonthlyReengage userId={this.state.user.user_id} obj={this.state} />
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                        {this.state.userBillingDetail.subscription_status=='ACTIVE' ? (  
                        <Subcription_Cancellation user_subscription_id={this.state.userBillingDetail.subscription_id} user_id={this.state.userBillingDetail.user_id} subscription_status={this.state.userBillingDetail.subscription_status}  billing_update={this.updateUserBillling} type="1" text="Request Cancellation" />) : <Subcription_Cancellation user_subscription_id={this.state.userBillingDetail.subscription_id} user_id={this.state.userBillingDetail.user_id} subscription_status={this.state.userBillingDetail.subscription_status}  billing_update={this.updateUserBillling} type="2" text="Subscribe" />}
                        
                          </div>
                    </div>
                    <Modal
                        show={this.state.openRefileamountModel}
                        footer={""}
                        title={"Update Refill Amount"}
                        onclose={this.closeModal}
                        body={this.formRefilelimitModel('amount')}
                    />
                    <Modal
                        show={this.state.openRefilelimitModel}
                        footer={""}
                        title={"Update Refill Drop Amount"}
                        onclose={this.closeModal}
                        body={this.formRefilelimitModel('limit')}
                    />
                </>
            )
        }
    }
}
