import React from "react";
import { Form, TextArea, Checkbox } from "formsy-semantic-ui-react";
import { Message, Dropdown } from "semantic-ui-react";
import { connect } from 'react-redux';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
import "./Popup.css";
import "semantic-ui-css/semantic.min.css";
import { addValidationRule } from "formsy-react";
import axios from "../../../../config/axios";
import api from "../../../../api";
import utilFunc from "../../../../util_funs";
import DialogBoxLocationSettings from "./DialogBoxLocationSettings";
import { setTableData, setParams } from '../../../../store/incompleteChat';
validationRule.isRequired();
validationRule.usPhoneLength();
validationRule.newPinAndOldPinNotSame();

class DialogBoxIndividualNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		show: props.obj.modalstatusNotiAandMisc,
		title: props.modaltitle,
		modalFormLoader: props.modalFormLoader,
		modalstatusNotiAandMiscData: props.modalstatusNotiAandMiscData,
		message1: "none",
		color1: "green",
		msg1: "Setting has been saved successfully.",
		message2: "none",
		message3: "none",
		memberLogo: "",
		imgError: "none",
		loading: "none",
		disableBtn: false,
		isd_code: "",
		jumpinMsg: "",
		switchValue1: "OFF",
		switchValue2: "OFF",
		switchValue3: "OFF",
		switchValue4: "OFF",
		switchValue5: "OFF",
		pin: "",
		cpin: "",
		pinMessage: "Pin Setting has been saved successfully.",
		pinMessageState: "none",
		locationSettingModalShow: false,
		locationSettingModalTitle: "",
		id: "",
		sessionUser: {},
		locationDataResult: [],
		locationDataGmap: [],
		locationState: [],
		userState: [],
		recieve_all_chat_if_not_in_current_location: "0",
		recieve_all_chat_if_not_in_current_locationChecked: true,
		email1: "",
		phone1: "",
		email1Ontime: "set",
		phone1Ontime: "set",
		ImgSetStatus: "FALSE",
		selectedLagacyLocation: '',
		notifyvia: {
			email: true,
			phonecall: false,
			text: false
		},
		selectedBranch : ['anybranch'],
		selectedBranchValues : [],
		webselectedBranch : ['anybranch'],
		selectedAnswers:[],
		gptselectedAnswers:[],
		gptselectedBranch : ['anybranch'],
		gptselectedBranchValues:[]

    };
    let modalstatusNotiAandMiscData = this.props.modalstatusNotiAandMiscData;

  }

  notiAndMiscmodalClose = () => {
    // if (this.state.switchValue1 == "OFF") {
    //   document.querySelector("#is_livechat_notofication").click();
    // }
    // if (this.state.switchValue2 == "OFF") {
    //   document.querySelector("#is_completedchat_notofication").click();
    // }
    document.querySelector("#manageNotificationIndiviNoti").reset();
    this.setState({
      memberLogo: "",
      imgError: "none",
      message1: "none",
      color1: "green",
      msg1: "Setting has been saved successfully.",
      message2: "none",
      message3: "none",
      loading: "none",
      isd_code: "",
      jumpinMsg: "",
      // switchValue1: "OFF",
      // switchValue2: "OFF",
      email1: "",
      phone1: "",
      notifyvia: {
        email: false,
        phonecall: false,
        text: false,
        isd_code:'',
      },
      selectedBranch : ['anybranch'],
      webselectedBranch : ['anybranch'],
      selectedBranchValues:[],
	  gptselectedBranchValues:[],
      gptselectedBranch : ['anybranch'],
      
      });
    this.props.notiAndMiscmodalClose(false, "manageNotificationIndiviNoti");
    this.setState({
      disableBtn: false
    })
  };
  random = () => {
    return Math.random();
  };

  renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {

    return defaultCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : ""}
        >
          {option.country_name}
        </option>
      );
    });
  };

  renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
    return remailCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : "qqq"}
        >
          {option.country_name}
        </option>
      );
    });
  };

  handleChangeISD = (e) => {
    this.setState({
      isd_code: e.target.value,
      disableBtn: false
    });
    // setIsd(e.target.value)
  };

  phoneFormat = (p) => {
    p = p.replace(/[^\d]*/gi, "");
    if (p.length <= 3) {
      return p;
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;
      return pp;
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      return finalPhone;
    }
  };
  handlePhoneChange = (e) => {
    let phone1 = e.target.value;
    let p = this.phoneFormat(phone1);
    setTimeout(function () {
      document.getElementById("phoneNumberIndiviNoti").value = p;
    }, 100);
    this.setState({
      phone1: e.target.value,
      email1: "",
      disableBtn: false
    });
  };

  handleEmailChange = (e) => {
    let email1 = e.target.value;
    this.setState({
      email1: e.target.value,
      phone1: "",
      disableBtn: false
    });
  };

  handleCompanyLogo = (event) => {
    // let newState = this.state.customizeinfo;
    let imageMIME = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
    if (
      imageMIME.includes(event.target.files[0].type) == true &&
      event.target.files[0].type != ""
    ) {
      // setImgError("none")
      var companyLogo = URL.createObjectURL(event.target.files[0]);
      this.setState({
        memberLogo: companyLogo,
        imgError: "none",
        ImgSetStatus: "TRUE",
        disableBtn: false
      });
    } else {
      this.setState({
        imgError: "block",
      });
    }
  };

  handleCompanyLogoRemove = (event) => {
    this.setState({
      memberLogo: "",
      imgError: "none",
      ImgSetStatus: "FALSE",
      disableBtn: false
    });
  };

  changeSwitch = (e) => {
    var val = "";

    if (e.target.value == "ON") {
      val = "OFF";
    } else {
      val = "ON";
    }
    if (e.target.name == "is_livechat_notofication") {
      this.setState({
        switchValue1: val,
        disableBtn: false
      });
      if (val == "OFF") {
        this.setState({
          showForLiveChat: "none",
          disableBtn: false
        });
      } else if (val == "ON") {
        this.setState({
          showForLiveChat: "block",
          disableBtn: false
        });
      }
    } else if (e.target.name == "is_completedchat_notofication") {
      this.setState({
        switchValue2: val,
        disableBtn: false,
		webselectedBranch: ['anybranch']
      });
    } else if (e.target.name == "is_incompletedchat_gpt_notofication") {
      this.setState({
        switchValue3: val,
      });
    } else if (e.target.name == "receive_voice_notification_individual") {
      this.setState({
        switchValue4: val,
        disableBtn: false,
        selectedBranch: ['anybranch']
      });
    } else if (e.target.name == "is_reported_notification") {
      this.setState({
        switchValue5: val,
        disableBtn: false
      })
    }
    //this.onValidSubmitSwitch(e.target.name, val);
    // document.querySelector(".manageNotificationIndiviNoti").reset();
  };
  onchangeenable = () => {
    this.setState({ disableBtn: false });
  }

  _handleLocationEvent = (event) => {
    this.setState({
      ...this.state,
      selectedLagacyLocation: event.target.value, disableBtn: false
    });
  }

  validateVoiceField = () => {
    return new Promise((resolve, reject) => {
      const notifyVia = this.state.notifyvia;
      if (notifyVia.email) {

      }
    });
  }

  onValidSubmitFirstColIndiviNoti = async (e) => {
    this.setState({
      loading: "block",
    });
    var ref = this;
    if (this.state.switchValue1 === "OFF" && this.state.switchValue2 === "OFF" && this.state.switchValue3 === "OFF" && this.state.switchValue4 === "OFF" && this.state.switchValue5 === "OFF") {
      this.setState({
        loading: "none",
        message1: "block",
        color1: "red",
        msg1: "Please select notification type",
      });
      
      setTimeout(function () {
        ref.setState({
          message1: "none",
        });
      }, 5000);
    }else if(this.state.switchValue4==="ON" && this.state.selectedBranch?.length===0){
        this.setState({
          loading: "none",
          message1: "block",
          color1: "red",
          msg1: "Please select branches",
        });
        setTimeout(function () {
          ref.setState({
            message1: "none",
          });
        }, 5000);
    } else {

      if (this.state.switchValue4 === 'ON') {
        let i = 0;
        for (const [key, value] of Object.entries(this.state.notifyvia)) {
          if (!value) {
            i++;
          }
        }
        if (i === Object.keys(this.state.notifyvia).length) {
          this.setState({
            notifyviaError: true,
            loading: "none",
          });
          return;
        }
      }

      this.setState({
        disableBtn: true
      })
      let notifyviaValue = [];
      for (const [key, value] of Object.entries(this.state.notifyvia)) {
        if (value) {
          notifyviaValue.push(key);
        }
      }
      var myform = document.getElementById("manageNotificationIndiviNoti");

      var formData = new FormData(myform);
      formData.append("user_id", this.props.sessionUser.user_id);
      formData.append("chatBot_id", this.props.sessionUser.chatBot_id);
      formData.set('notifyvia', notifyviaValue.join(','));
      formData.set('voice_branch', this.state.selectedBranch.join(','));
      formData.set('web_branch', this.state.webselectedBranch.join(','));
      formData.set('voice_values', JSON.stringify(this.state.selectedAnswers));
	  formData.set('gpt_voice_values', JSON.stringify(this.state.gptselectedAnswers));
	  formData.set('gpt_voice_branch', this.state.gptselectedBranch?.join(','));
      formData.set('type', this.props.obj.voice_type);
      api.manageUser
        .IndividualNotificationsSetting(formData)
        .then((resp) => {
          document.getElementById("notificationSetingModalPopup").scroll({
            top: 0,
            behavior: "smooth", // 
          });
          var message = "";

          if (resp.data.status == true) {
            let tmgs = (resp.data.message1 && typeof resp.data.message1 != 'undefined') ? resp.data.message1 : '';
            if (tmgs.length > 0) {
              message = message + "" + tmgs;
            }


            api.manageUser.getIncompletedChatLists({}).then((data) => {
              try {
                this.props.setTableData({ data: data.data })
              } catch (err) {
                console.log(err);
              }
            });
           
            tmgs = (resp.data.message && typeof resp.data.message != 'undefined') ? resp.data.message : '';
            if (tmgs.length != "") {
              message = message + " " + tmgs;
            }
            tmgs = (resp.data.voiceCompleteMsg && typeof resp.data.voiceCompleteMsg != 'undefined') ? resp.data.voiceCompleteMsg : '';
            if (tmgs.length != "") {
              message = message + " " + tmgs;
            }
            tmgs = (resp.data.reportCompleteMsg && typeof resp.data.reportCompleteMsg != 'undefined') ? resp.data.reportCompleteMsg : '';
            if (tmgs.length != "") {
              message = message + " " + tmgs;
              
            }
            tmgs = (resp.data.incompleteGptError && typeof resp.data.incompleteGptError != 'undefined') ? resp.data.incompleteGptError : '';
            if (tmgs.length != "") {
              message = message + " " + tmgs;
            }
            tmgs = (resp.data?.chatReportError && typeof resp?.data?.chatReportError != 'undefined') ? resp?.data?.chatReportError : '';
            if (tmgs.length != "") {
              message = message + " " + tmgs;
            }
            if (resp.data.onlyvoice && (typeof resp?.data?.voiceCompleteError!='undefined' || typeof resp?.data?.voiceCompleteMsg!='undefined')) {
              let vmsg=resp?.data?.voiceCompleteError?.length ? resp?.data?.voiceCompleteError : resp?.data?.voiceCompleteMsg;
              message = " " + typeof vmsg!='undefined' ? vmsg: '';
            }
            
            this.setState({
              ImgSetStatus: "FALSE",
              loading: "none",
              message1: "block",
              color1: "green",
              msg1: message,
              disableBtn: false,
            });
            var ref = this;
            setTimeout(function () {
              ref.setState({
                message1: "none",
              });
            }, 5000);
            this.props.UpdateToasterMsg(this.state);
            this.props.reloadDataTable();
            this.notiAndMiscmodalClose();
          } else if (resp.data.status == false) {
            if (!resp.data.onlyincomplete) {
              this.props.UpdateCompleteNotification(resp.data.data?.completedAll);
              this.props.UpdateLiveNotification(resp.data.data?.liveAll);
              let tmgss1 = (resp.data.incompleteGptError && typeof resp.data.incompleteGptError != 'undefined') ? resp.data.incompleteGptError : '';
              if (tmgss1.length != "") {
                message = message + " " + tmgss1;
              }
              tmgss1 = (resp.data.chatReportError && typeof resp.data.chatReportError != 'undefined') ? resp.data.chatReportError : '';
              if (tmgss1.length != "") {
                message = message + " " + tmgss1;
              }
              tmgss1 = (resp.data.message && typeof resp.data.message != 'undefined') ? resp.data.message : '';
              if (tmgss1.length != "") {
                message = message + " " + tmgss1;
              }

            } else {
              let tmgss = (resp.data.incompleteGptError && typeof resp.data?.incompleteGptError != 'undefined') ? resp.data?.incompleteGptError : '';
              if (tmgss.length != "") {
                message = message + " " + tmgss;
              }
              tmgss = (resp.data?.chatReportError && typeof resp.data.chatReportError != 'undefined') ? resp.data?.chatReportError : '';
              if (tmgss.length != "") {
                message = message + " " + tmgss;
              }
              tmgss = (resp.data?.message && typeof resp.data.message != 'undefined') ? resp.data.message : '';
              if (tmgss.length != "") {
                message = message + " " + tmgss;
              }
              tmgss = (resp.data?.message1 && typeof resp.data.message1 != 'undefined') ? resp.data?.message : '';
              if (tmgss.length != "") {
                message = message + " " + tmgss;
              }

              if (resp.data?.onlyvoice) {
                message = message + " " + resp?.data?.voiceCompleteError ? resp?.data?.voiceCompleteError : resp?.data?.message;
              }
            }
            this.setState({
              loading: "none",
              message1: "block",
              color1: "red",
              msg1: message,
            });
            var ref = this;
            setTimeout(function () {
              ref.setState({
                message1: "none",
              });
            }, 5000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  changeNotifyVia = (fieldname) => {
    const notifyvia = this.state.notifyvia;
    notifyvia[fieldname] = !notifyvia[fieldname];
    this.setState({
      notifyvia: notifyvia,
      notifyviaError: false,
      disableBtn: false
    })
  }

  branchOptionList = () => {
    const firstIndex = {
      key: 'anybranch',
      text: 'Any branch',
      value: 'anybranch'
    };
    const key = 'branch_name';
    let  filterlist = [...new Map(this.props.obj.workspaceBranches.map(item =>
		[item[key], item])).values()];
		let list = filterlist.map((key, index) => {
		return {
			key: key.branch_id,
			text: key.branch_name,
			value: key.branch_id
		}
    });
    list.splice(0, 0, firstIndex);
    return list;
  }
	//branchlist option for gpt parsedata
	gptbranchOptionList = () => {
		const firstIndex = {
			key: 'anybranch',
			text: 'Any branch',
			value: 'anybranch'
		};
		const key = 'branch_name';
		let  filterlist = [...new Map(this.props.obj.gptworkspaceBranches.map(item =>
			[item[key], item])).values()];
			let list = filterlist.map((key, index) => {
			return {
				key: key.branch_id,
				text: key.branch_name,
				value: key.branch_id
			}
		});
		list.splice(0, 0, firstIndex);
		return list;
	}   

	branchOptionListval=(item)=>{
		let key=item[0]?.key;
		let vals=item[0]?.val;
		if(vals!=''){
		vals=vals.map((v)=>{
			return {key:v,text:v,value:v}
		});
		return vals;
		}
		return [];
	}

	gptbranchOptionListval=(item)=>{
		let key=item[0]?.key;
		let vals=item[0]?.val;
		let filteredvalue = this.props.obj.gpt_parsed_values.filter(element => {
			return element.key == key
		})
	
		if(filteredvalue[0].val!=''){
		vals=filteredvalue[0].val.map((v)=>{
			return {key:v,text:v,value:v}
		});
		return vals;
		}
		return [];
	}


	branchOptionValues = (list) => {
		let vals=[];
		if(list.length > 0) {
		list=list.filter((v)=>v!=='anybranch');
		list=list.filter((v)=>v.trim()!=='anybranch' && v.trim()!=='Any branch');
		vals=list.map((branch,i)=>{
				if(branch!=='anybranch' && branch!=='Any branch'){
				let s=this.props.obj.parsed_values.filter((vall,i,arr)=>{
					return vall['key']==branch;
				});
				return  s;
				}
		});
		}
		let savedvlas=this.state.selectedAnswers;
		if(vals.length===0){
		savedvlas=[];      
		}
		if(vals.length && savedvlas.length){
		let savedvlas_copy=savedvlas;
		let newans=savedvlas.filter((val,i)=>{
				let j=vals.findIndex((val1)=>{
					return val.key===  val1[0]?.key
				});
				if(j>=0){
				return true;
				}
		});
			savedvlas=newans;
		}
		this.setState({selectedBranchValues:vals,selectedAnswers:savedvlas});
		
	}

	gptbranchOptionValues = (list) => {
		let vals=[];
		if(list.length > 0) {
			list=list.filter((v)=>v!=='anybranch');
			list=list.filter((v)=>v.trim()!=='anybranch' && v.trim()!=='Any branch');
			vals=list.map((branch,i)=>{
				if(branch!=='anybranch' && branch!=='Any branch'){
					let s=this.props.obj.gpt_parsed_values.filter((vall,i,arr)=>{
						return vall['key']==branch;
					});
					return  s;
				}
			});
		}
		let savedvlas=this.state.gptselectedAnswers.length ? this.state.gptselectedAnswers : [];
		savedvlas = savedvlas.filter(element => {
			return list.includes(element[0].key.trim());
		});
		if(vals.length){
			vals.forEach(val => {
				if(val[0].val.length ){
					if(!savedvlas.length){
						savedvlas.push([{key:val[0].key,val:[]}])
					}else{
						let s = [];
						s=savedvlas.filter((vall,i,arr)=>{
							return vall[0].key==val[0].key;
						});
						if(!s.length){
							savedvlas.push([{key:val[0].key,val:[]}])
						}
					}
				}
			});
		}
		this.setState({gptselectedBranchValues:savedvlas,gptselectedAnswers:savedvlas});
	}

	getvalueOptions =(val)=>{
		if(val!=='') {
		return '';
		} 
		return val.split(",");
	}

	// branch lists for web branch list
	webbranchOptionList = () => {
		const firstIndex = {
		key: 'anybranch',
		text: 'Any branch',
		value: 'anybranch'
		};
		let list = this.props.obj.webworkspaceBranches.map((key, index) => {
		return {
			key: key.branch_id,
			text: key.branch_name,
			value: key.branch_id
		}
		});
		list.splice(0, 0, firstIndex);
	
		return list;
	}

	handleBranchSelection = (e, d) => {
		this.setState({selectedBranch : d.value},()=>{
		if(this.props.obj.voice_type=="Turbo"){
			this.branchOptionValues(this.state.selectedBranch);
		}
		})
	}

	gpthandleBranchSelection = (e, d) => {
		this.setState({gptselectedBranch : d.value},()=>{
			this.gptbranchOptionValues(this.state.gptselectedBranch);
		})
	}


	handlewebBranchSelection = (e, d) => {
		this.setState({webselectedBranch : d.value})
	}
	handleOptionSelection=(e,d)=>{
		let values=this.state.selectedAnswers;
		let search=values.findIndex((item)=>item.key==d.name);
		if(search>-1){
		let find=values[search];
		find['values']=d.value;
		values[search]=find;
		}else{
		values.push({key:d.name,values:d.value});
		}
		this.setState({selectedAnswers:values});
	}

	

	gpthandleOptionSelection=(e,input)=>{
		let existingArray=this.state.gptselectedAnswers ?? [];
		// Extract the name and value array from the input
		const inputName = input.name;
		const inputValues = input.value;
		
		let existingEntry1 = [];
		existingArray.forEach(element => {
			if(element[0].key == inputName){
				element[0].val = inputValues;
			}
			existingEntry1.push(element);
		});

		this.setState({gptselectedAnswers:existingEntry1,gptselectedBranchValues:existingEntry1});
	}

	render() {
		const errorLabel = <div className="red" />;
		return (
		<React.Fragment>
			{
			<div
				style={{ display: this.state.loading }}
				class="ui loading form mh-loading-custom"
			></div>
			}
			<div
			id="notificationSetingModalPopup"
			className={
				this.props.obj.modalstatusNotiAandMisc
				? "modal fade show"
				: "modal fade"
			}
			data-backdrop="static"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="staticBackdrop"
			aria-hidden="true"
			style={{
				display: this.props.obj.modalstatusNotiAandMisc ? "block" : "none",
			}}
			>
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
				<button
					type="button"
					className="close linear-gradient"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => this.notiAndMiscmodalClose()}
				>
					X
				</button>
				{this.state.modalFormLoader && (
					<div className="ui loading form"></div>
				)}
				<div className="modal-body">
					<div className="row" style={{ width: 100 + "%" }}>
					<div className="col-lg-12">
						<div>
						<Form
							noValidate
							autoComplete="off"
							className="manageNotificationIndiviNoti"
							id="manageNotificationIndiviNoti"
							onValidSubmit={this.onValidSubmitFirstColIndiviNoti}
						>
							<div className="yr-popBox-1">
							<div className="box-title mb-1">
								Individual Notifications:
							</div>
							<Message
								color={this.state.color1}
								style={{
								display: this.state.message1,
								}}
							>
								{this.state.msg1}
							</Message>

							<div
								className="form-group row"
								style={{ marginBottom: 0, height: "45px" }}
							>
								<label className="col-5 col-form-label">
								Live chat notifications
								</label>
								<div className="col-7">
								<span
									className={
									(this.state.switchValue1 == ""
										? this.props.obj.notiAndSettingmodalData
										.is_livechat_notoficationChecked == true
										? "ON"
										: "OFF"
										: this.state.switchValue1) == "ON"
										? "kt-switch kt-switch-sm kt-switch--success chat-cus-check"
										: "kt-switch kt-switch-sm kt-switch--error chat-cus-check"
									}
								>
									<label>
									<input
										name="is_livechat_notofication"
										id="is_livechat_notofication"
										type="checkbox"
										value={
										this.state.switchValue1 == ""
											? this.props.obj.notiAndSettingmodalData
											.is_livechat_notoficationChecked ==
											true
											? "ON"
											: "OFF"
											: this.state.switchValue1
										}
										onClick={(e) => this.changeSwitch(e)}
										defaultChecked={
										this.props.obj.notiAndSettingmodalData
											.is_livechat_notoficationChecked
										}
									/>
									<span />
									</label>
								</span>
								</div>
							</div>
							<div
								className="form-group row"
								style={{ marginBottom: 0, height: "45px" }}
							>
								<label className="col-5 col-form-label">
								Incomplete GPT chat notification
								</label>
								<div className="col-7">
								<span
									className={
									(this.state.switchValue3 == ""
										? this.props.obj.notiAndSettingmodalData
										.is_incompletedchat_gpt_notoficationChecked == true
										? "ON"
										: "OFF"
										: this.state.switchValue3) == "ON"
										? "kt-switch kt-switch-sm kt-switch--success chat-cus-check"
										: "kt-switch kt-switch-sm kt-switch--error chat-cus-check"
									}
								>
									<label>
									<input
										name="is_incompletedchat_gpt_notofication"
										id="is_incompletedchat_gpt_notofication"
										type="checkbox"
										value={
										this.state.switchValue3 == ""
											? this.props.obj.notiAndSettingmodalData
											.is_incompletedchat_gpt_notoficationChecked ==
											true
											? "ON"
											: "OFF"
											: this.state.switchValue3
										}
										onClick={(e) => this.changeSwitch(e)}
										defaultChecked={
										this.props.obj.notiAndSettingmodalData
											.is_incompletedchat_gpt_notoficationChecked
										}
									/>
									<span />
									</label>
								</span>
								</div>
							</div>

							<div
								className="form-group row"
								style={{ marginBottom: 10, height: "45px" }}
							>
								<label className="col-5 col-form-label">
								Completed chat notifications
								</label>
								<div className="col-7">
								<span
									className={
									(this.state.switchValue2 == ""
										? this.props.obj.notiAndSettingmodalData
										.is_completedchat_notoficationChecked ==
										true
										? "ON"
										: "OFF"
										: this.state.switchValue2) == "ON"
										? "kt-switch kt-switch-sm kt-switch--success chat-cus-check"
										: "kt-switch kt-switch-sm kt-switch--error chat-cus-check"
									}
								>
									<label>
									<input
										type="checkbox"
										name="is_completedchat_notofication"
										id="is_completedchat_notofication"
										value={
										this.state.switchValue2 == ""
											? this.props.obj.notiAndSettingmodalData
											.is_completedchat_notoficationChecked ==
											true
											? "ON"
											: "OFF"
											: this.state.switchValue2
										}
										onClick={(e) => this.changeSwitch(e)}
										defaultChecked={
										this.props.obj.notiAndSettingmodalData
											.is_completedchat_notoficationChecked
										}
									/>
									<span />
									</label>
								</span>
								</div>
							</div>

							{/*  */}
							<div
								className="form-group row"
								style={{ marginBottom: 10, height: "45px" }}
							>
								<label className="col-5 col-form-label">
								AI phone call notifications
								</label>
								<div className="col-7">
								<span
									className={
									(this.state.switchValue4 == ""
										? this.props.obj.notiAndSettingmodalData
										.receive_voice_notification_individual ==
										true
										? "ON"
										: "OFF"
										: this.state.switchValue4) == "ON"
										? "kt-switch kt-switch-sm kt-switch--success chat-cus-check"
										: "kt-switch kt-switch-sm kt-switch--error chat-cus-check"
									}
								>
									<label>
									<input
										type="checkbox"
										name="receive_voice_notification_individual"
										id="receive_voice_notification_individual"
										value={
										this.state.switchValue4 == ""
											? this.props.obj.notiAndSettingmodalData
											.receive_voice_notification_individual ==
											true
											? "ON"
											: "OFF"
											: this.state.switchValue4
										}
										onClick={(e) => this.changeSwitch(e)}
										defaultChecked={
										this.props.obj.notiAndSettingmodalData
											.receive_voice_notification_individual
										}
									/>
									<span />
									</label>
								</span>
								</div>
							</div>
							<div
								className="form-group row"
								style={{ marginBottom: '15px', height: "45px" }}
							>
								<label className="col-5 col-form-label">
								Reported chats/calls notifications
								</label>
								<div className="col-7">
								<span
									className={
									(this.state.switchValue5) == "ON"
										? "kt-switch kt-switch-sm kt-switch--success chat-cus-check"
										: "kt-switch kt-switch-sm kt-switch--error chat-cus-check"
									}
								>
									<label>
									<input
										name="is_reported_notification"
										id="is_reported_notification"
										type="checkbox"
										value={this.state.switchValue5 == "ON" ? "ON" : "OFF"}
										onClick={(e) => this.changeSwitch(e)}
									// defaultChecked={
									//   this.props.obj.notiAndSettingmodalData
									//     .is_livechat_notoficationChecked
									// }
									/>
									<span />
									</label>
								</span>
								</div>
							</div>


							{(this.state.showForLiveChat == 'block' ? 'block' : (this.state.switchValue3 == 'ON' || this.state.switchValue4 == 'ON' || this.state.switchValue5 == 'ON' || this.state.switchValue1 == 'ON')) && <div
								className="form-group"
								style={{
								marginBottom: 10,
								display: this.state.showForLiveChat == 'block' ? 'block' : (this.state.switchValue3 == 'ON' || this.state.switchValue4 == 'ON' || this.state.switchValue5 == 'ON' || this.state.switchValue1 == 'ON') ? "block" : 'none'
								}}
							>
								<label style={{ marginBottom: 0 }}>Full Name</label>

								{this.state.showForLiveChat == "none" ? (
								<Form.Input
									type="text"
									className="1"
									name="username"
									validations="isRequired"
									validationErrors={{
									isRequired: "This field is required.",
									}}
									onChange={this.onchangeenable}
									errorLabel={errorLabel}
								/>
								) : (
								<Form.Input
									type="text"
									className=""
									name="username"
									validations="isRequired"
									validationErrors={{
									isRequired: "This field is required.",
									}}
									onChange={this.onchangeenable}
									errorLabel={errorLabel}
								/>
								)}
							</div>}
							{(this.state.switchValue1 == 'ON' || this.state.switchValue2 == 'ON' || this.state.switchValue3 == 'ON' || this.state.switchValue4 == 'ON' || this.state.switchValue5 == 'ON') &&
								<div
								className="form-group"
								style={{ marginBottom: 10 }}
								>
								<label style={{ marginBottom: 0 }}>
									Email Address
								</label>

								{(this.state.phone1 == "" &&
									this.state.email1Ontime != "") || (this.state.switchValue4 == 'ON' && this.state.notifyvia.email) ? (
									<Form.Input
									type="email"
									className=""
									name="email"
									validations="isEmail,isRequired"
									validationErrors={{
										isEmail:
										"Please enter a valid email address.",
										isRequired: "This field is required.",
									}}
									errorLabel={errorLabel}
									onChange={this.handleEmailChange}
									id="emailIndiviNoti"
									/>
								) : (
									<Form.Input
									type="email"
									className=""
									name="email"
									errorLabel={errorLabel}
									onChange={this.handleEmailChange}
									id="emailIndiviNoti"
									/>
								)}
								</div>
							}
							{((this.state.switchValue3 == 'ON' || this.state.switchValue5 == 'ON') && (this.state.switchValue1 == 'OFF' && this.state.switchValue2 == 'OFF' && this.state.switchValue4 == 'OFF')) || (this.state.switchValue1 == 'OFF' && this.state.switchValue2 == 'OFF' && this.state.switchValue3 == 'OFF' && this.state.switchValue4 == 'OFF' && this.state.switchValue5 == 'OFF') ? "" :
								<div
								className="form-group"
								style={{ marginBottom: 10 }}
								>
								<label>Phone Number</label>
								<div className="d-flex">
									<select
									class="form-control"
									name="isd_code"
									id="isd_code"
									onChange={this.handleChangeISD}
									// id="isd_code_id"
									style={{ maxWidth: 166, marginRight: "1rem" }}
									defaultChecked='+1'
									defaultValue='+1'
									>
									{this.renderDropDownDefault(
										this.props.obj.ISDrenderOption1,
										this.props.obj.notiAndSettingmodalData.isd
									)}
									<optgroup label="Other countries">
										{this.renderDropDownRemaining(
										this.props.obj.ISDrenderOption2,
										this.props.obj.notiAndSettingmodalData.isd
										)}
									</optgroup>
									</select>

									{(this.state.email1 == "" &&
									this.state.phone1Ontime != "") || (this.state.notifyvia.phonecall || this.state.notifyvia.text) ? (
									<Form.Input
										fluid
										autoComplete="off"
										name="phone"
										maxLength="13"
										onChange={this.handlePhoneChange}
										placeholder="Phone Number"
										validations="isRequired,usPhoneLength"
										validationErrors={{
										isRequired: "This field is required.",
										usPhoneLength:
											"Phone number must be at least 10 numbers.",
										}}
										id="phoneNumberIndiviNoti"
										className="w-100"
										errorLabel={errorLabel}
									/>
									) : (
									<Form.Input
										fluid
										autoComplete="off"
										name="phone"
										maxLength="13"
										onChange={this.handlePhoneChange}
										placeholder="Phone Number"
										id="phoneNumberIndiviNoti"
										className="w-100"
										errorLabel={errorLabel}
									/>
									)}
								</div>
								</div>
							}

							{
								this.state.switchValue2 != 'OFF' ?
								(<div
									className="form-group"
									style={{ marginBottom: 10 }}
								>
									<label>Chat Location</label>
									<div className="d-flex">
									<select class="form-control" onChange={(e) => this._handleLocationEvent(e)} name="lagacySelectedLocation">
										<option>All Locations</option>
										{this.props.legacybuttons()}
									</select>
									</div>
								</div>) : ''
							}

							<div
								style={{
								display: this.state.showForLiveChat == 'block' ? 'block' : 'none',
								}}
							>

								<div className="row">
								<div
									className="form-group col-md-6"
									style={{ marginBottom: 10 }}
								>
									<label style={{ marginBottom: 10 }}>
									Default Jump In Message
									</label>
									<TextArea
									className=""
									// validations="isRequired"
									// validationErrors={{
									//   isRequired: "This field is required.",
									// }}
									id="jump_in_question"
									placeholder="Default Jump In Message"
									rows="5"
									name="jump_in_question"
									onChange={this.onchangeenable}
									></TextArea>
								</div>

								<div className="form-group col-md-6">
									<label>Profile Pic (optional)</label>
									<div
									className="logo-img"
									style={{ height: 100, marginTop: 5 }}
									>
									<img
										src={
										this.state.memberLogo == ""
											? this.props.obj.notiAndSettingmodalData
											.image == "" ||
											this.props.obj.notiAndSettingmodalData
												.image == null
											? "assets/media/logos/upload-bg.png"
											: this.props.sessionUser.assetPath +
											"/images/chatlogo/" +
											this.props.obj
												.notiAndSettingmodalData.image +
											"?v=" +
											this.random()
											: this.state.memberLogo
										}
										alt=""
									/>
									</div>
									<div className="d-flex justify-content-center">
									<div className="upload-btn-wrapper">
										<button className="btn linear-gradient zy-save-btn width-100">
										Edit Logo
										</button>
										<input
										type="file"
										name="image"
										id="comanyLogoId"
										onChange={this.handleCompanyLogo}
										accept="image/jpg,image/png,image/jpeg,image/gif"
										/>
									</div>
									{this.state.memberLogo && (
										<button
										type="reset"
										className="btn linear-gradient zy-cancel-btn ml-2"
										onClick={this.handleCompanyLogoRemove}
										>
										Remove
										</button>
									)}
									</div>
									<div
									style={{
										display: this.state.imgError,
										color: "red",
									}}
									>
									Invalid file format. Only "jpg, jpeg, png,
									gif" files are supported.
									</div>
									{/* <input type="hidden" id="dbimgchat" name="dbimgchat"  value={this.state.companyLogo}/> */}
									{/* <input type="hidden" id="oldChatImgID" name="oldChatImgname" value={this.props.cplogo}/> */}
									<input
									type="hidden"
									id="ImgSetStatus"
									name="ImgSetStatus"
									value={this.state.ImgSetStatus}
									/>
								</div>
								</div>
							</div>
							{
								(this.state.switchValue4 === 'ON' )  && <div className="row notify-via">
								<div className="col-md-6">
									<div className="form-group">
									<label>Notification Type</label>
									<select className="form-control" name="notificationtype" onChange={this.onchangeenable}>
										<option value="1">All notifications</option>
										<option value="2">Schedule based</option>
									</select>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
									<label>Notify via</label>
									<Checkbox
										name="notifyvia"
										// value={1} 
										label={{ children: 'Email' }}
										onChange={() => this.changeNotifyVia('email')}
										defaultChecked={this.state.notifyvia.email}
									/>
									<Checkbox name="notifyvia" label={{ children: 'Text' }} onChange={() => this.changeNotifyVia('text')} defaultChecked={this.state.notifyvia.text} />
									<Checkbox name="notifyvia" label={{ children: 'Phone Call' }} onChange={() => this.changeNotifyVia('phonecall')} defaultChecked={this.state.notifyvia.phonecall} />
									{this.state.notifyviaError && <div class="red">This field is required.</div>}

									</div>
					

								</div>
								</div>
							}
							{
								(this.state.switchValue4 == 'ON' || this.state.switchValue2 == 'ON') && this.props.obj.gptworkspaceBranches.length &&
								<>
								<div className="row">
									
								{	
								this.state.switchValue2 == 'ON' &&
									<>
										<div className="form-group col-md-6 mt-2">
										<label> Web Notify if call goes through</label>
										<Dropdown
											key={Math.random()*100}
											placeholder=''
											fluid
											multiple
											search
											selection
											options={this.webbranchOptionList()}
											defaultValue={this.state.webselectedBranch}
											onChange={this.handlewebBranchSelection}
											// value={this.state.selectedBranch}
										/>
										</div>
										<div className="form-group col-md-6 mt-2">
											<label>Web Notify if call contains these parse</label>
											<Dropdown
											key={Math.random()*100}
											placeholder=''
											fluid
											multiple
											search
											selection
											options={this.gptbranchOptionList()}
											defaultValue={this.state.gptselectedBranch}
											onChange={this.gpthandleBranchSelection}
											// value={this.state.selectedBranch}
										/>
										</div>
									</>
								}
								{	this.state.switchValue4 == 'ON' &&
								<div className="form-group col-md-6 mt-2">
									<label>Voice Notify if call goes through</label>
									<Dropdown
									key={Math.random()*100}
										placeholder=''
										fluid
										multiple
										search
										selection
										options={this.branchOptionList()}
										defaultValue={this.state.selectedBranch}
										onChange={this.handleBranchSelection}
										// value={this.state.selectedBranch}
									/>
								</div>
								}
								</div>
								</>
							}
							{	
								(this.state.switchValue4 == 'ON' && this.state.selectedBranchValues.length>0) && this.props.obj.voice_type=="Turbo" && <div style={{width:'100%'}}>
									<div className="row" style={{marginTop:"20px"}}>
										{this.state.selectedBranchValues.map((item,i)=>{
										if(item && item!=''){
											if(item[0]?.val==""){
											return (<p></p>);
											}
											return(
											<div className="col-md-6" key={i}>
												<label>Select values for {item[0]?.key}</label>
										<Dropdown
											key={i}
											name={item[0]?.key}
											placeholder=''
											fluid
											multiple
											search
											selection
											options={this.branchOptionListval(item)}
											//defaultValue={this.state.gptselectedAnswers[i][0]?.val}
											onChange={this.handleOptionSelection}
											// value={item[0]?.val ?? []}
										/>
											</div>
											
										);
										}
										})}
										
									</div>
									

								</div>
							}
							{	
								(this.state.switchValue2 == 'ON' ) && <div style={{width:'100%'}}>
								<div className="row" style={{marginTop:"20px"}}>
									{this.state.gptselectedBranchValues.map((item,i)=>{
									
									if(item && item!=''){
										return(
											<>
												<div className="col-md-6" key={i}>
													<label>Select values for web parse {item[0]?.key}</label>
													<Dropdown
														key={i}
														name={item[0]?.key}
														placeholder=''
														fluid
														multiple
														search
														selection
														options={this.gptbranchOptionListval(item)}
														defaultValue={item[0]?.val ?? []}
														onChange={this.gpthandleOptionSelection}
														value={item[0]?.val ?? []}
													/>
												</div>
											</>
										
									);
									}
									})}
									
								</div>

								</div>
							}
							<div className="row">
								{
								(this.state.switchValue3 == 'ON' || this.state.switchValue4 == 'ON' || this.state.switchValue2 == 'ON' || this.state.switchValue1 == 'ON' || this.state.switchValue5 == 'ON') &&
								<div
									className="col-lg-12 text-center"
									style={{ marginTop: 33 }}
								>
									<button
									type="submit"
									class="btn linear-gradient yr-submitbtn"
									disabled={this.state.disableBtn}
									// onClick={this.onValidSubmitFirstColIndiviNoti}
									>
									Save
									</button>
								</div>
								}
							</div>
							</div>
						</Form>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</React.Fragment>
		);
	}
}

export default connect(null, { setTableData })(DialogBoxIndividualNotifications)
// export default DialogBoxIndividualNotifications;
