import React from "react";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Segment, Table, Message, Item, Dropdown } from "semantic-ui-react";
import { Form, TextArea, Checkbox } from "formsy-semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import { DatatableFilter } from "./DatatableFilter";
import DialogBoxDetail from "./DialogBoxDetail";
import { DatatableRow } from "./DatatableRow";
import utilFunc from "../../../../../util_funs";

const tableHeader = [
  {
    title: "Actions",
    width: "1",
    sort: "",
  },
  {
    title: "Email/Phone",
    width: "3",
    sort: "email",
  },
  {
    title: "Location",
    width: "2",
    sort: "",
  },{
    title: "Notification Branches",
    width: "3",
    sort: "",
  }
];
const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
const APIURL = "http://localhost/zyratalk/api/premium/settings/manageuser/";
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "user_id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalErrorMsgLegacy: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      modalstatusNotiAandMisc: false,
      modalstatusNotiAandMiscData: {},
      ISDrenderOption1: [],
      ISDrenderOption2: [],
      notiAndSettingmodalData: {},
      loadNow: false,
      currentChecked: [],
      currentExpanded: [],
      hipaaLoginEmailList: [],
      hipaaLoginPhoneList: [],
      messageDisplay:"none",
      updateAction:false,
      notifyviaError1:false,
      gptselectedAnswers:[],
      gptselectedBranchValues:[],
      gptselectedBranch : ['anybranch'],
	    gptworkspaceBranches:[]
    };
  }

  componentDidMount() {
    this.loadData({});
      if(this.props.profiledata && this.props.profiledata.profileDetails){
        const remailCountryCodeArr = this.props.profiledata.remailCountryCodeArr;
        const defaultCountryCodeArr = this.props.profiledata.defaultCountryCodeArr;
        this.setState({ ISDrenderOption1: defaultCountryCodeArr });
        this.setState({ ISDrenderOption2: remailCountryCodeArr });
      }
    // this.getAccessModules();
    // api.user
    //   .getProfile()
    //   .then((resp) => {
    //     if (resp.data && resp.data.data.profileDetails) {
    //       const remailCountryCodeArr = resp.data.data.remailCountryCodeArr;
    //       const defaultCountryCodeArr = resp.data.data.defaultCountryCodeArr;
    //       this.setState({ ISDrenderOption1: defaultCountryCodeArr });
    //       this.setState({ ISDrenderOption2: remailCountryCodeArr });
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.profiledata && nextProps.profileDetails){
      const remailCountryCodeArr = nextProps.profiledata.remailCountryCodeArr;
      const defaultCountryCodeArr = nextProps.profiledata.defaultCountryCodeArr;
      this.setState({ ISDrenderOption1: defaultCountryCodeArr });
      this.setState({ ISDrenderOption2: remailCountryCodeArr });
    }
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter) => {
    if (filter !== this.state.q) {
      this.loadData({ q: filter, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {
    if (data.modalType == "Delete") {
      this.onDeleteCompletedChatAction();
    }
    // if (data.modalType == 'Popup') {
    //   this.setState({
    //     modalstatus: false,
    //     modaltitle: '',
    //     modalmessage: '',
    //     modalbuttonmsg: {},
    //     modalType: ''
    //   });
    // }
  };

  deleteCompletedChat = (id, table) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Delete",
      modaldata: modaldata,
    });
  };

  setCurrentCheck = (checked) => {
    this.setState({
      currentChecked: checked,
    });
  };
  setCurrentExpand = (checked) => {
    this.setState({
      currentExpanded: checked,
    });
  };

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
      updateAction:false,
    });
  };
  reloadDataTable = () => {
    this.loadData();
  };
  onDeleteCompletedChatAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageUser
      .deleteLegacyCompleteChatMember({
        id: this.state.modaldata.id,
        table: this.state.modaldata.table,
      })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          let recordDeleted = this.state.lists.findIndex(
            (list) => list.id === data.data.id
          );
          let oldArr = this.state.lists;
          oldArr.splice(recordDeleted, 1);
          this.setState({
            modalstatus: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modalErrorMsg: false,
          });
         
          this.setState({
            loading: "none",
            message2: "block",
            color2: "green",
            msg2: "Legacy completed chat notification member has been deleted successfully.",
          });
          this.props.UpdateToasterMsgThree(this.state);
          this.loadData({});
        } catch (err) {
          console.log(err);
        }
      });
  };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    // let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    // let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    // let time_zone = clientTimeZone;

    // params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.manageUser.getCompletedChatLists(query).then((data) => {
        try {
          // let accessModuleStore = data.user_access;
          // data.user_access.forEach((item, index) => {
          //   item.forEach((item2, index2) => {
          //     accessModuleStore.push(item2);
          //   });
          // });
          let datatableRowstemp;
          var isd1 = "+1";
          var phoneno = "";
          var is_recieve_text_message_on_livechat = "0";
          var is_recieve_email_on_livechat = "0";
          var jumpin_message = "";
          var is_recieve_text_message_on_completechat = "0";
          var is_recieve_email_on_completechat = "0";
          var is_leave_note = "0";
          if (data.data.totalrecord) {
            datatableRowstemp = data.data.list.map((list, index) => (
              <DatatableRow
                key={index}
                list={list}
                editUserSubAccounts={this.editUserSubAccounts}
                deleteHipaaUserSubAccounts={this.deleteHipaaUserSubAccounts}
                preOpensetting={this.preOpensetting}
                sessionUser={this.props.sessionUser}
                deleteCompletedChat={this.deleteCompletedChat}
                editMember={this.editMember}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  editMember = (id) => {
    this.setState({ loading: true });
    api.manageUser.getwebWorkspaceBranch({}).then((res) => {
      if (res.status) {
        let recordlist = this.state.lists.findIndex((list) => list.id === id);
        let modaldata = {...this.state.lists[recordlist]};
        let selected=[];
        if(modaldata.web_branch){
          selected=modaldata.branch_list;
        }
        modaldata.existing_email=modaldata.email;
        modaldata.existing_phone_number=modaldata.phone_number;
        modaldata.popup_type="";
        if(modaldata.email!==""){
            modaldata.popup_type="email";
        }
        if(modaldata.phone_number!==""){
          modaldata.popup_type="phone";
      	}
        let selectedData = [];
        if( modaldata.gpt_voice_branch ){
			
        }
        console.log(modaldata.gpt_voice_branch,"item vishal" );
        this.setState({
          modaldata: modaldata,
          modalbuttonmsg: { submit: "Submit", cancel: "Close" },
          modalstatus: true,
          modaltitle: "Edit notification member details",
          modalType: "",
          modalFooter: false,
          workspaceId: res.data.worksplace_id,
          workspaceBranches: res.data.data,
          gptworkspaceBranches:res.data.parseData,
          gptselectedBranchValues:modaldata.gpt_voice_values ? JSON.parse(modaldata.gpt_voice_values) : [],
          gptselectedBranch:modaldata.gpt_voice_branch ? JSON.parse(modaldata.gpt_voice_branch) : [],
          selectedBranch: selected,
          loading: false,
          updateAction:true,
          notifyviaError1:false
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
         
        });
      }
    })
  }

  phoneFormat = (p) => {
    p = p.replace(/[^\d]*/gi, "");
    if (p.length <= 3) {
      return p;
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;
      return pp;
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      return finalPhone;
    }
  };

  handlePhoneChange = (e) => {
    const memberData = this.state.modaldata;
    let phone1 = e.target.value;
    let p = this.phoneFormat(phone1);
    memberData.phone_number = p;
    setTimeout(function () {
      document.getElementById("phoneNumberIndiviNoti").value = p;
    }, 100);
    this.setState({
      modaldata: memberData,
      email1: "",
      disableBtn: false
    });
  };

  gpthandleOptionSelection=(e,input)=>{
    let existingArray=this.state.gptselectedBranchValues ?? [];
		// Extract the name and value array from the input
		const inputName = input.name;
		const inputValues = input.value;
		
		let existingEntry1 = [];
		existingArray.forEach(element => {
      if(element[0].key == inputName){
				element[0].val = inputValues;
        console.log(element,inputValues,"vihsla hello");
			}
			existingEntry1.push(element);
		});

		this.setState({gptselectedBranchValues:existingEntry1},() => {
      this.setState({
        modalmessage: this.memberEditBody()
      });
    });
  }

 /*  gpthandleOptionSelection=(e,d)=>{
		const inputvalues = d.value
		let values=this.state.gptselectedAnswers;
		let search=values.findIndex((item)=>item.key==d.name);
		if(search>-1){
			let find=values[search];
			find['val']=inputvalues;
			values[search]=find;
		}else{
		values.push([{key:d.name,val:inputvalues}]);
		}
		this.setState({gptselectedAnswers:values});
	} */
  handleChangeISD = (e) => {
    const memberData = this.state.modaldata;
    memberData.country_code = e.target.value;
    this.setState({
      modaldata: memberData,
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
  };
  renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
    return defaultCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : ""}
        >
          {option.country_name}
        </option>
      );
    });
  };
  renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
    return remailCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : "qqq"}
        >
          {option.country_name}
        </option>
      );
    });
  };
  branchOptionList = () => {
    const firstIndex = {
      key: 'anybranch',
      text: 'Any branch',
      value: 'anybranch'
    };
    const key = 'branch_name';
    let  filterlist = [...new Map(this.state.workspaceBranches.map(item =>
      [item[key], item])).values()];
    let list = filterlist.map((key, index) => {
      return {
        key: key.branch_id,
        text: key.branch_name,
        value: key.branch_id
      }
    });
    list.splice(0, 0, firstIndex);
    return list;
  }
	//comple chat notification by parse data dropdown functions
	gptbranchOptionList = () => {
		const firstIndex = {
			key: 'anybranch',
			text: 'Any branch',
			value: 'anybranch'
		};
		const key = 'branch_name';
    if(this.state.gptworkspaceBranches?.branches){
      let  filterlist = [...new Map(this.state.gptworkspaceBranches.branches.map(item =>
        [item[key], item])).values()];
        let list = filterlist.map((key, index) => {
        return {
          key: key.branch_id,
          text: key.branch_name,
          value: key.branch_id
        }
      });
      list.splice(0, 0, firstIndex);
      console.log(list,"list..")
      return list;
    }else{
      return [];
    }
	
	}  
	gptbranchOptionListval=(item)=>{
		let key=item[0]?.key;
        // starts from here
        const found = this.state.gptworkspaceBranches?.values.find(item => item.key === key);
        if(found){
        if(found.val?.length){
            return found.val.map((v)=>{
                return {key:v,text:v,value:v}
            });
        }
        }
        return [];
	}

	gptbranchOptionValues = (list) => {
    let vals=[];
		if(list.length > 0) {
			list=list.filter((v)=>v!=='anybranch');
			list=list.filter((v)=>v.trim()!=='anybranch' && v.trim()!=='Any branch' && v != "");
			vals=list.map((branch,i)=>{
				if(branch!=='anybranch' && branch!=='Any branch'){
					let s=this.state.gptworkspaceBranches.values.filter((vall,i,arr)=>{
						return vall['key']==branch;
					});
					return  s;
				}
			});
		}
		let savedvlas=this.state.gptselectedBranchValues.length ? this.state.gptselectedBranchValues : [];
		savedvlas = savedvlas.filter(element => {
			return list.includes(element[0].key.trim());
		});
		if(vals.length){
			vals.forEach(val => {
				if(val[0].val?.length ){
					if(!savedvlas.length){
						savedvlas.push([{key:val[0].key,val:[]}])
					}else{
						let s = [];
						s=savedvlas.filter((vall,i,arr)=>{
							return vall[0].key==val[0].key;
						});
						if(!s.length){
							savedvlas.push([{key:val[0].key,val:[]}])
						}
					}
				}
			});
		}
		this.setState({gptselectedBranchValues:savedvlas},() => {
      this.setState({
        modalmessage: this.memberEditBody()
      });
    });
	}

	gpthandleBranchSelection = (e, d) => {
    let values = d.value.filter(el => el != "");
		this.setState({gptselectedBranch : d.value},()=>{
			this.gptbranchOptionValues(this.state.gptselectedBranch);
		})
	}

	// complete chat notification by parse data dropdown functions end
    memberEditBody = () => {
        console.log("name", this.state.modaldata.name);
        const errorLabel = <div className="red" />;
        return (
        <div className="col-md-12">
            <Message
            color={this.state.color}
            style={{
                display: this.state.messageDisplay,
            }}
            >
            {this.state.resMessage}
            </Message>
            <Form
            noValidate
            autoComplete="off"
            className="manageNotificationIndiviNoti"
            id="manageNotificationIndiviNoti"
            onValidSubmit={this.onValidSubmitMember}
            style={{marginBottom:'15px'}}
            >
            
            {this.state.modaldata.popup_type=='email' && <div
                className="form-group"
                style={{ marginBottom: 10 }}
            >
                <label style={{ marginBottom: 0 }}>
                Email Address
                </label>
                <Form.Input
                    type="email"
                    className=""
                    name="email"
                    validations="isEmail,isRequired"
                    validationErrors={{
                    isEmail:
                        "Please enter a valid email address.",
                    isRequired: "This field is required.",
                    }}
                    errorLabel={errorLabel}
                    onChange={this.onchangeenable}
                    id="emailIndiviNoti"
                    defaultValue={this.state.modaldata.email}
                />
                
            </div>}
            {this.state.modaldata.popup_type=='phone' &&  <div
                className="form-group"
                style={{ marginBottom: 10 }}
            >
                <label>Phone Number</label>
                <div className="d-flex">
                <select
                    class="form-control"
                    name="isd_code"
                    id="isd_code"
                    
                    onChange={this.handleChangeISD}
                    style={{ maxWidth: 166, marginRight: "1rem" }}
                    defaultValue={this.state.modaldata.isd_code}
                >
                    {this.renderDropDownDefault(
                    this.props.ISDrenderOption1
                    )}
                    <optgroup label="Other countries">
                    {this.renderDropDownRemaining(
                        this.props.ISDrenderOption2
                    )}
                    </optgroup>
                </select>
                
                    <Form.Input
                    fluid
                    autoComplete="off"
                    name="phone_number"
                    maxLength="13"
                    onChange={this.handlePhoneChange}
                    placeholder="Phone Number"
                    id="phoneNumberIndiviNoti"
                    className="w-100"
                    validations="isRequired,usPhoneLength"
                    validationErrors={{
                        isRequired: "This field is required.",
                        usPhoneLength:
                        "Phone number must be at least 10 numbers.",
                    }}
                    errorLabel={errorLabel}
                    defaultValue={this.state.modaldata.phone_number}
                    />
                
                </div>
            </div>}
            <div className="row form-group" style={{ marginBottom: 10 }}>
                <div className="col-md-6">
                <div className="form-group">
                    <label>Chat Location</label>
                    <select class="form-control" onChange={(e) => this._handleLocationEvent(e)} name="lagacySelectedLocation" defaultValue={this.state.modaldata.location_id}>
                                        <option>All Locations</option>
                                        {this.props.legacybuttons()}
                                    </select>
                </div>
                </div>

            
            </div>
            <div className="form-group">
                <label>Web Notify if call goes through</label>
                <Dropdown
                placeholder=''
                fluid
                multiple
                search
                selection
                options={this.branchOptionList()}
                defaultValue={this.state.selectedBranch}
                onChange={this.handleBranchSelection}
                id={this.state.selectedBranch}
                />
                {this.state.notifyviaError1 && <div class="red">This field is required.</div>}
            </div>
            {/*  parse data cature start here */}
            <>
                <div className="form-group col-md-6 mt-2">
                    <label>Web Notify if call contains these parse</label>
                    <Dropdown
                    key={Math.random()*100}
                    placeholder=''
                    fluid
                    multiple
                    search
                    selection
                    options={this.gptbranchOptionList()}
                    defaultValue={this.state.gptselectedBranch}
                    onChange={this.gpthandleBranchSelection}
                    // value={this.state.selectedBranch}
                    />
                </div>
                {	
                (this.state.gptselectedBranchValues?.length>0) && 
                <div style={{width:'100%'}}>
                    <div className="row" style={{marginTop:"20px"}}>
                        {this.state.gptselectedBranchValues.map((item,i)=>{
                        if(item && item!=''){
                            return(
                            <div className="col-md-6" key={i}>
                                <label>Select values for web parse {item[0]?.key}</label>
                                <Dropdown
                                    key={i}
                                    name={item[0]?.key}
                                    placeholder=''
                                    fluid
                                    multiple
                                    search
                                    selection
                                    options={this.gptbranchOptionListval(item)}
                                    defaultValue={item[0]?.val}
                                    onChange={this.gpthandleOptionSelection}
                                    value={item[0]?.val}
                                />
                            </div>
                            
                        );
                        }
                    })}
                    </div>
                </div>
            }
            </>
            {/* parse data cature ends here */}

            <div className="form-group" style={{ display: 'flex', marginTop: 10, columnGap: 10, justifyContent: 'right' }}>
                <button
                type="button"
                onClick={() => this.modalClose(false, "")}
                style={{ display: this.state.modalbuttonmsg.cancel != "" ? "block" : "none" }}
                className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                data-dismiss="modal"
                >
                <span>Close</span>
                </button>
                <button
                disabled={this.state.buttonDisable}
                type="submit"
                // onClick={() => this.modalbuttofun(this.state.modalType)}
                style={{ display: this.state.modalbuttonmsg.submit != "" ? "block" : "none" }}
                className="btn linear-gradient yr-submitbtn btn-sm"
                >
                Submit
                </button>
            </div>
            </Form>
        </div>
        )
    }
    handleBranchSelection = (e, d) => {
    console.log(d.value);
     const memberData = this.state.modaldata;
     memberData.voice_brances = d.value;
     this.setState({ selectedBranch: d.value,  modaldata: memberData,notifyviaError1:false},()=>{
      this.setState({
        modalmessage: this.memberEditBody(),
      })
     });
    }
   _handleLocationEvent = (event) => {
    let mod_data=this.state.modaldata;
    mod_data['location_id']=event.target.value
    this.setState({
      modaldata: mod_data,
      disableBtn: false
    });
    }

  onchangeenable = (e) => {
    const memberData = this.state.modaldata;
    memberData[e.target.name] = e.target.value;
    this.setState({
      modaldata: memberData,
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
  }
  onValidSubmitMember = () => {
   
    let selbranch=this.state.selectedBranch ?? [];
    selbranch=selbranch?.length===1 && selbranch[0]?.trim()==='' ? []:selbranch;
    
    if (selbranch.length===0) {
      this.setState({
        notifyviaError1: true
      }, () => {
        this.setState({
          modalmessage: this.memberEditBody()
        })
      });
      return;
    }
    this.setState({
      loading: true,
      buttonDisable: true
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
    let pararam={...this.state.modaldata,selectedBranch:selbranch,gpt_voice_branch:this.state.gptselectedBranch,
		gpt_voice_values:this.state.gptselectedBranchValues}
    api.manageUser.updateCompNotificationMember(pararam).then((res) => {
      this.setState({ loading: false, buttonDisable: false })
      if (res.data.status) {
        this.setState({
          messageDisplay: 'block',
          color: 'green',
          resMessage: res.data.message
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
      } else {
        this.setState({
          messageDisplay: 'block',
          color: 'red',
          resMessage: res.data.message
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
      }
      setTimeout(() => {
        this.setState({
          messageDisplay: 'none'
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
        if(res.data.error!=="duplicate" && res.data.status){
          this.loadData({});
          this.modalClose(false, "");
        }
       
      }, 2000);
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
    return (
      <Segment>
        {this.state.loading && <div className="ui loading form"></div>}
        <DatatableFilter
          filter={this.state.q}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
        />
        <DialogBoxDetail
          obj={this.state}
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalClose={this.modalClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
          currentChecked={this.state.currentChecked}
          currentExpanded={this.state.currentExpanded}
          setCurrentCheck={this.setCurrentCheck}
          setCurrentExpand={this.setCurrentExpand}
          updateAction={this.state.updateAction}
        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
        />
      </Segment>
    );
  }
}
